import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/reducers";
import React, {useEffect, useState} from "react";
import { users } from "../../../../utils/firebase";
import AccountHeader from "../account-header/account-header"
import CustomContainer from "../../../../components/general/contianer";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore/lite";

const PointsHistory = () => {

    const { userData } = useSelector((state: RootState) => state.user);

    const [loading, setLoading] = useState<boolean>(true);
    const [points, setPoints] = useState<any[]>([]);

    useEffect(() => {
        getDocs(query(collection(users, userData.uid, "points_history"), orderBy("created", "desc"), limit(10) ))
            .then((snapshot) => {
                if (snapshot.empty) {
                    setLoading(false);
                    setPoints([]);
                } else {
                    let t: any[] = []
                    snapshot.docs.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        t.push(data);
                    })
                    setPoints(t);
                    setLoading(false);
                }
            })
    }, [])
    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            {!loading && points.length ===0 &&
                <p className={"text-lg font-sans font-medium"}>
                    You haven't placed any orders yet. Place an order to earn rewards.
                </p>
            }
            {points.length > 0 &&
                points.map((pDetails) => {

                    return (
                        <div style={{ background: "white", padding: "12px 18px", marginBottom: "8px", border: "1px solid rgb(240,240,240)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div>
                                <p className={"text-base font-sans font-semibold"}>
                                    {pDetails.type==="order" ? `Purchase #${pDetails.orderId}` : `Welcome to ${pDetails.description}`}
                                </p>
                                <p className={"text-sm font-sans"}>
                                    {pDetails.type==="order" ? `${pDetails.description}` : ""}
                                </p>
                                <p className={"text-sm font-sans  opacity-70 mt-1"}>
                                    {pDetails.created.toDate().getDate() + "/" + (pDetails.created.toDate().getMonth()+1) + "/" + pDetails.created.toDate().getFullYear()}
                                </p>
                            </div>

                            <p className={"text-base font-sans font-semibold text-green-600"}>
                                {`+${pDetails.points}`}
                            </p>
                        </div>
                    )
                })
            }
        </CustomContainer>
    )

}

export default PointsHistory;