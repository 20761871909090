import React from "react";
import {useSelector} from "react-redux";
import { RootState } from "../../../../redux/reducers"
import FormWrapper from "../../../../components/form-components/form-wrapper"
import FormInput from "../../../../components/form-components/form-input-field"
import FormButton from "../../../../components/form-components/form-button"
import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from "firebase/auth"
import { auth } from "../../../../utils/firebase"

const ChangePassword = ({onClose}: {onClose: () => void}) => {

    const { userData } = useSelector((state: RootState) => state.user);

    return (
        <div>
            <FormWrapper
                id={"change_password_form"}
                className={"space-y-4"}
                onSubmit={(values) => {
                    return new Promise(async (resolve, reject) => {
                        await signInWithEmailAndPassword(auth, userData?.email?.toString() ?? "", (values.oldPassword?.toString() ?? ""))
                            .then(async (u) => {
                                if (u)
                                    await reauthenticateWithCredential(u.user, EmailAuthProvider.credential(userData?.email?.toString(), values.oldPassword.toString()));
                                updatePassword(auth.currentUser, (values.newPassword?.toString() ?? "")).then(() => {
                                    onClose();
                                    resolve("");
                                }).catch((err) => {
                                    switch (err.code) {
                                        case "auth/weak-password":
                                            return reject({ newPassword: "Password must contain 6+ characters, a symbol & a number." });
                                        default:
                                            return reject("Something went wrong on our side.");
                                    }
                                })
                            })
                            .catch((err) => {
                                switch (err.code) {
                                    case "auth/wrong-password":
                                        return reject({ oldPassword: "That's an incorrect password. Try again." });
                                    default:
                                        return reject("Something went wrong on our side.");
                                }
                            });
                    })
                }}
            >
                <FormInput id={"change_password_old_password"} name={"oldPassword"} type={"password"} placeholder={"Old Password"} label={"Old Password"} required/>
                <FormInput id={"change_password_new_password"} name={"newPassword"} type={"password"} placeholder={"New Password"} label={"New Password"} required />
                <div style={{ height: "8px" }}/>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <FormButton style={{  maxWidth: "280px" }}>
                        Change Password
                    </FormButton>
                </div>
                <div style={{ height: "8px" }}/>
            </FormWrapper>
        </div>
    )

}

export default ChangePassword;