import React from "react";

interface FormCheckBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | React.ReactNode,
}

const FormCheckBox = (props: FormCheckBoxProps) => {

    return (
        <div className={"flex items-start"}>
            <input
                {...props}
                type="checkbox"
                className="h-4 w-4 transform translate-y-1 text-primary-default focus:ring-primary-default border-gray-300 rounded cursor-pointer"
            />
            <label htmlFor={props.id} className="ml-2 block font-sans text-sm sm:text-base text-gray-900">
                {props.label}
            </label>
        </div>
    )

}

export default FormCheckBox;