import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FormattedAddress from "../../../../components/model-widgets/formatted-address";
import InlineLink from "../../../../components/general/inline-link";
import EditAddress from "./edit-address";
import FormButton from "../../../../components/form-components/form-button";
import { RootState } from "../../../../redux/reducers"
import Address from "../../../../models/address"
import AccountHeader from "../account-header/account-header"
import CustomContainer from "../../../../components/general/contianer"
import Fade from "../../../../components/transition-wrapper/fade";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore/lite"
import { users } from "../../../../utils/firebase"
import Loading from "../../../../components/general/loading"
import ZoomFadeDialog from "../../../../components/dialogs/zoom-fade-dialog"
import { FiPlus } from "@react-icons/all-files/fi/FiPlus"
import { FiX } from "@react-icons/all-files/fi/FiX"

const YourAddresses = () => {

    const { userData } = useSelector((state: RootState) => state.user);

    const [addresses, setAddresses] = useState<Address[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [deleting, setDeleting] = useState("");
    const [editing, setEditing] = useState<Address | undefined>();

    useEffect(() => {
        if (loading) {
            getDocs(collection(users, userData.uid, "addresses"))
                .then((snapshot) => {
                    if (!snapshot.empty) {
                        let t: Address[] = [];
                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data['id'] = doc.id;
                            t.push(new Address(data));
                        })
                        setAddresses(t);
                    } else{
                        setAddresses([]);
                    }
                    setDeleting("");
                    setOpenDialog(false)
                    setEditing(undefined);
                    setLoading(false);
                });
        }
    }, [loading])

    const deleteAddress = (id: string) => {
        setDeleting(id);
        deleteDoc(doc(collection(users, userData.uid, "addresses"), id))
            .then(() => {
                setLoading(true);
                setDeleting(id);
            });
    }

    const editAddress = async (address : Address) => {
        await setEditing(address);
        await setOpenDialog(true);
    }

    const cardClass = "flex rounded-xl transition duration-200 hover:shadow-special hover:border-background-light border-2 border-background-dark px-5 py-4 items-start bg-background-lighter";

    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            <Fade show={!loading}>
                <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"}>
                        {addresses.length === 0 &&
                            <div>
                                <p className={"text-lg font-sans font-medium"}>
                                    You currently don't have any saved shipping addresses. Add an address here to be prefilled for quicker checkout.
                                </p>
                                <div style={{ display: "flex", width: "100%", justifyContent: "flex-start", marginTop: "24px" }}>
                                    <FormButton
                                        disableArrowAnimation
                                        onClick={() => {
                                            setOpenDialog(true);
                                        }} style={{  maxWidth: "200px", margin: "unset" }}>
                                                Add Address
                                    </FormButton>
                                </div>
                            </div>
                        }
                        {addresses.length !== 0 &&
                            <div>
                                <div className={`${cardClass} cursor-pointer justify-center space-x-3 items-center h-full`} onClick={() => { setOpenDialog(true); }}>
                                    <FiPlus size={20} strokeWidth={"1.5px"} color={"rgb(20,20,40)"}/>
                                    <p className={"text-sm sm:text-base font-sans font-medium select-none"}>
                                        Add Address
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            addresses.map((a) =>{
                                return (
                                    <div key={a.id}>
                                        <div className={`${cardClass} flex-col relative`}>
                                            {
                                                deleting === a.id &&
                                                <div className={"absolute w-full h-full left-0 top-0 flex justify-center items-center"} style={{
                                                    background: "rgb(255,255,255,0.8)",
                                                    zIndex: 1251
                                                }}>
                                                    <Loading />
                                                </div>
                                            }

                                            <div style={{ minHeight: "136px" }}>
                                                <FormattedAddress address={a}/>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                <InlineLink to={""} className={"font-sans font-medium text-sm"} variant={"secondary"} onClick={() => { editAddress(a).then() }}>
                                                    Edit
                                                </InlineLink>
                                                <InlineLink to={""} className={"font-sans font-medium text-sm ml-3"} variant={"secondary"} style={{ color: "rgb(210,71,71)" }} onClick={() => {deleteAddress(a.id)}}>
                                                    Remove
                                                </InlineLink>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                </div>
            </Fade>
            <ZoomFadeDialog open={openDialog} onClose={() => { setOpenDialog(false); setEditing(undefined); }}>
                <div className={"p-6"}>
                    <div className={"flex items-center space-x-1 pb-4 mb-4 border-b border-gray-300"}>
                        <div role={"button"} className={"p-2 transform -translate-x-2 rounded-full hover:text-red-900 hover:bg-background-dark cursor-pointer"} onClick={() => { setOpenDialog(false); setEditing(undefined); }}>
                            <FiX size={18} />
                        </div>
                        <p className={"font-bold font-sans text-2xl"}>
                            Shipping Address
                        </p>
                    </div>
                    <EditAddress
                        address={editing}
                        onClose={async () => {
                            setLoading(true)
                        }}
                    />
                </div>
            </ZoomFadeDialog>
        </CustomContainer>
    )
}

export default YourAddresses;