import React, { useState } from "react"

interface FormTextareaProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    disableRounded?: boolean,
    label?: string,
    hideLabel?: boolean,
    error?: string,
    hideShadow?: boolean,
    rootClassName?: string
    end?: React.ReactNode
    endClick?: () => void
}

const FormTextarea = (props: FormTextareaProps) => {

    const inputProps = {...props};
    delete inputProps.disableRounded
    delete inputProps.label
    delete inputProps.hideLabel
    delete inputProps.error
    delete inputProps.hideShadow
    delete inputProps.rootClassName

    return (
        <div className={`relative font-sans ${props.rootClassName}`}>
            <div className={`relative -space-y-px ${props.hideShadow ? "" : "rounded-md shadow-sm"}`}>
                <textarea {...inputProps}
                       className={`resize-none bg-background-lighter ${props.className} appearance-none rounded-none relative block w-full px-3 ${(props.value?.toString()?.length ?? 0) > 0 ? "pt-4.5 pb-1.5" : "py-3" } transition duration-200 border ${props.error ? "border-red-300 z-10" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:${props.error ? "ring-red-400" : "ring-primary-light"} focus:${props.error ? "border-red-400" : "border-primary-light"} focus:z-20 sm:text-sm ${props.disableRounded ? "" : "rounded-md"}`}
                       disabled={props.disabled}
                />
                {(props.end) &&
                    <div role="button" className={"hover:opacity-80 absolute h-full flex items-center right-3 z-20 top-1/2 transform -translate-y-1/2 uppercase tracking-widest text-xs"} onClick={() => { props.endClick() }}>
                        {props.end}
                    </div>
                }
            </div>
            <label htmlFor={inputProps.id} className={`${props.hideLabel ? "sr-only" : `transform ${(props.value?.toString()?.length ?? 0) > 0 ? "opacity-100 -translate-y-0" : "-translate-y-1 opacity-0"} bg-background-lighter transition duration-200 uppercase tracking-widest absolute z-20 top-px left-px px-3 pt-1.5 rounded-full`}`} style={{ fontSize: "10px" }}>
                {props.label}
            </label>
            {(!!props.error && props.error !== "empty") &&
            <p style={{ textTransform:"none"}} className={"-space-y-px text-sm mt-1 mb-1 text-red-700"}>
                {props.error}
            </p>
            }
        </div>
    )

}

export default FormTextarea;