import { useLocation } from "@reach/router"
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ManageProductReview from "./manage-product-review";
import { ProductItem } from "../../../../models/product-item"
import { RootState } from "../../../../redux/reducers"
import ZoomFadeDialog from "../../../../components/dialogs/zoom-fade-dialog"
import Fade from "../../../../components/transition-wrapper/fade";
import { getDocs, query, where } from "firebase/firestore/lite"
import { products } from "../../../../utils/firebase";
import AccountHeader from "../account-header/account-header"
import CustomContainer from "../../../../components/general/contianer";
import Rating from "react-rating"
import { AiOutlineStar } from "@react-icons/all-files/ai/AiOutlineStar"
import { AiFillStar } from "@react-icons/all-files/ai/AiFillStar"
import { FiX } from "@react-icons/all-files/fi/FiX"

const PendingReviews = () => {

    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state.user);

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState<string>();
    const [productItemsData, setProductItemsData] = useState<ProductItem[]>([]);

    useEffect(() => {
        getProductData().then(() => setLoading(false));
    }, [])

    const getProductData = async () => {
        let productItems: ProductItem[] = [];
        let productItemsIDs = [...(userData?.productsBought ?? [])];
        productItemsIDs.filter((v,i,s) => s.indexOf(v) === i);

        if (productItemsIDs.length > 0) {
            for (let i = 0; i < productItemsIDs.length; i+=10) {

                let productId: string[] = [];
                productItemsIDs.slice(i, i+10).forEach(p => {
                    if (!productId.includes(p))
                        productId.push(p);
                })

                await getDocs(query(products, where("id", "in", productId))).then(async (snapshot) => {
                    if (!snapshot.empty) {
                        snapshot.docs.forEach((doc) => {
                            let documentData = doc.data();
                            documentData.id = doc.id;
                            productItems.push(new ProductItem(documentData));
                        })
                    }
                    if (productItems.length === productItemsIDs.length) {
                        await setProductItemsData(productItems);
                        return;
                    }
                })
            }
        }
    }

    const pendingProductReviews = productItemsData.filter((p) => !userData?.productsReviewed.includes(p.id))
    const completedProductReviews = productItemsData.filter((p) => userData?.productsReviewed.includes(p.id))

    console.log(completedProductReviews);

    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            <Fade show={!loading}>
                <div className={"grid grid-cols-2 gap-3 md:grid-cols-3 md:gap-6 xl:grid-cols-4 xl:gap-8"}>
                    {
                        pendingProductReviews.length > 0 &&
                        pendingProductReviews.map((p) => {
                            return <div className={"relative w-full h-full flex flex-col rounded-md overflow-hidden shadow-inner border border-gray-200"} key={p.id} style={{ background :"rgb(240,239,234)" }}>
                                <div className={"relative group w-full aspect-h-10 aspect-w-9"} style={{ background: `#e7e8e3 url("${p.catalogue_images.imageKitLink("default")}") center center / cover` }}/>
                                <div style={{ height: "12px" }}/>
                                <p className={"font-sans font-medium text-sm sm:text-base text-center"}>
                                    {p.title}
                                </p>
                                <div style={{ height: "6px" }}/>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Rating
                                        // icon={<AiFillStar style={{ marginLeft: "2px" }} size={28} />}
                                        emptySymbol={<AiOutlineStar style={{ marginLeft: "2px" }} className={"text-gray-300"} size={22} />}
                                        fullSymbol={<AiFillStar style={{ marginLeft: "2px" }} className={"text-yellow-500"} size={22} />}
                                        // value={0}
                                        // precision={1}
                                        onChange={( newValue) => {
                                            setItem(`${p.id}:${newValue}`)
                                        }}
                                    />
                                </div>
                                <div style={{ height: "12px" }}/>
                            </div>
                        })
                    }
                </div>
            </Fade>
            <ZoomFadeDialog onClose={() => { setItem("") }} open={!!(item) && !loading}>
                <div className={"px-6 py-6"}>
                    <div className={"flex items-center space-x-1 pb-4 mb-4 border-b border-gray-300"}>
                        <div role={"button"} className={"p-2 transform -translate-x-2 rounded-full hover:text-red-900 hover:bg-background-dark cursor-pointer"} onClick={() => { setItem("") }}>
                            <FiX size={18} />
                        </div>
                        <p className={"font-bold font-sans text-2xl"}>
                            Write a Review
                        </p>
                    </div>
                    <ManageProductReview product={productItemsData.find((p) => p.id === item?.split(":")[0])} onClose={() => { setItem("") } }/>
                </div>
            </ZoomFadeDialog>
        </CustomContainer>
    )
}

export default PendingReviews;