import React, { useEffect, useState } from "react"
import {Order} from "../../../../models/order";
import moment from "moment";
import InlineLink from "../../../../components/general/inline-link";
import FormattedAddress from "../../../../components/model-widgets/formatted-address";
import { createCurrencyText } from "../../../../utils/fomatters";

const OrderListItem = ({order} : {order: Order}) => {

    const [size, setSize] = useState("");
    let xsDown = size === "small";

    useEffect(() => {
        if (document.documentElement.clientWidth < 500) {
            setSize("small");
        } else {
            setSize("large")
        }
    }, [])

    return (
        <div className={"bg-background-lighter border border-gray-300 rounded-lg overflow-hidden"}>
            <div style={{ padding: xsDown ? "12px 18px" : "18px 24px" }}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <p className={"text-base font-sans font-semibold"}>
                        #{order.orderId}
                    </p>
                </div>
                <div style={{ borderBottom: "1px solid rgba(150,150,150,0.2)", width: "100%", height: xsDown ? "8px" : "14px", marginBottom: xsDown ? "14px" : "20px" }}/>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div style={{ flex: "1" }}>
                        <p className={"text-xs font-sans font-normal"}>
                            Order Placed
                        </p>
                        <p className={"text-sm font-sans font-medium"}>
                            {order.created.getDate() + "/" + (order.created.getMonth()+1) + "/" + order.created.getFullYear()}
                        </p>
                    </div>
                    <div style={{ flex: "0.5" }}>
                        <p className={"text-xs font-sans font-normal"}>
                            Total
                        </p>
                        <p className={"text-sm font-sans font-medium"}>
                            {createCurrencyText(order.total, {})}
                        </p>
                    </div>
                    <div style={{ flex: "2", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <p className={"text-xs font-sans font-normal"}>
                            Ship To
                        </p>
                        <p className={"text-sm font-sans font-medium"}>
                            {/*<InlineLink variant={"secondary"} style={{ textDecoration: "underline" }}>*/}
                                {order.shipping?.name}
                            {/*</InlineLink>*/}
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ borderBottom: "1px solid rgba(150,150,150,0.2)", width: "100%" }}/>
            <div className={"bg-background-light"} style={{ padding: xsDown ? "0px 18px" : "0px 24px" }}>
                <div style={{ overflowX:"auto", WebkitOverflowScrolling: "touch" }}>
                    <div style={{ display: "-webkit-box", alignItems: "center" }}>
                        {
                            order.items.map((item, index: number) => {
                                return <div key={index} style={{ position: "relative" }}>
                                    <div style={{
                                        position: "relative",
                                        background: `#f1f1f1 url(${item.thumbnail}) center center / cover`,
                                        width: "84px",
                                        height: "84px",
                                        marginRight: xsDown ? "18px" : "24px",
                                        marginBottom: xsDown ? "18px" : "24px",
                                        marginTop: xsDown ? "18px" : "24px",
                                        borderRadius: "4px",
                                        overflow: "hidden"
                                    }}/>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default OrderListItem;