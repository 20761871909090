import React from "react";
import Orders from "../../../images/my-account-icons/orders.png";
import Addresses from "../../../images/my-account-icons/addresses.png";
import Loyalty from "../../../images/my-account-icons/loyalty-program.png";
import Security from "../../../images/my-account-icons/security.png";
import Reviews from "../../../images/my-account-icons/review.png";
import CustomContainer from "../../../components/general/contianer"
import AccountHeader from "./account-header/account-header"
import CustomTransitionLink from "../../../components/transition-wrapper/custom-transition-link"
import { Link } from "gatsby"

let HEADER_OPTIONS: { title:string, subtitle?: string, image?: string, to: string }[] = [
    {title: "Your Orders", subtitle: "Track, return or buy things again", image: Orders, to: "/account/orders"},
    {title: "Your Addresses", subtitle: "Edit addresses for orders", image: Addresses, to: "/account/addresses"},
    {title: "Login & Security", subtitle: "Edit login, name and birth date", image: Security, to: "/account/profile"},
    {title: `Review your purchases`, subtitle: "Help us make our products better", image: Reviews, to: "/account/review-purchases"},
    // {title: "Payment Options", subtitle: "Edit or add payment options", image: Payments, to: "/account/payment-options"},
    {title: `Your Points History`, subtitle: "View your points activity", image: Loyalty, to: "/account/point-history"}
]

const Overview = ({transitionStatus}) => {

    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-5 mb-4"}>
                {HEADER_OPTIONS.map((h, index) => {
                    return  <Link to={h.to} key={index} className={"flex rounded-xl transition duration-200 hover:shadow-special border-2 border-background-dark px-5 py-4 items-start hover:border-background-light bg-background-lighter"}>
                            <div className={`flex items-center justify-center flex-shrink-0 transform translate-y-1 mr-5`} >
                                <img src={h.image} alt={h.title} width={36}/>
                            </div>
                            <div>
                                <div style={{ marginLeft: "12px" }}>
                                    <p className={"font-sans text-base font-semibold "}>
                                        {h.title}
                                    </p>
                                    <p className={"font-sans text-sm opacity-70 font-medium -mt-px"}>
                                        {h.subtitle}
                                    </p>
                                </div>
                            </div>
                        </Link>
                })}
                {/*<Grid item xs={12} md={4} lg={3} xl={3}>*/}
                {/*    <Link to={'/collections/combos'}>*/}
                {/*        <div className={classes.highlight2} style={{ border: "1px solid rgba(150,150,150,0.2)", boxShadow: "0 0 14px 0 rgba(0,0,0,0.04)" }}>*/}
                {/*            <div style={{ background: "url(https://firebasestorage.googleapis.com/v0/b/groomd-mens.appspot.com/o/popups%2FDSC_8777.jpeg?alt=media) center center / cover", height: "280px", width: "100%" }}/>*/}
                {/*            <div style={{ height: "20px" }}/>*/}
                {/*            <Text variant={'overline'} component={'p'} medium style={{ padding: "0 16px", transform: "scale(0.8)", textAlign: "center" }}>*/}
                {/*                SPECIAL OFFER*/}
                {/*            </Text>*/}
                {/*            <Text variant={'body1'} exBold style={{ padding: "0 16px", textAlign: "center" }}>*/}
                {/*                Flat 10% of on Combos*/}
                {/*            </Text>*/}
                {/*            <div style={{ height: "20px" }}/>*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</Grid>*/}
            </div>
        </CustomContainer>
    )
}

export default Overview;