import React, {useEffect, useState} from "react";
import FormWrapper from "../../../../components/form-components/form-wrapper";
import FormButton from "../../../../components/form-components/form-button";
import Dropzone, { IFileWithMeta, IPreviewProps } from "react-dropzone-uploader";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import { ProductItem } from "../../../../models/product-item"
import { RootState } from "../../../../redux/reducers"
import { UserActionTypes } from "../../../../redux/types/user-type"
import Review from "../../../../models/review"
import { setUser } from "../../../../redux/actions/user/user-actions"
import { UserData } from "../../../../models/user-data"
import Rating from "react-rating"
import "./manage-product-review.css"
import FormTextarea from "../../../../components/form-components/form-textarea"
import { auth, products, users } from "../../../../utils/firebase"
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { arrayUnion, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore/lite"
import { AiFillStar } from "@react-icons/all-files/ai/AiFillStar"
import { AiOutlineStar } from "@react-icons/all-files/ai/AiOutlineStar"
import { FiPlus } from "@react-icons/all-files/fi/FiPlus"
import { FiX } from "@react-icons/all-files/fi/FiX"
import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle"
import app from "gatsby-plugin-firebase-v9.0"

const ManageProductReview = ({product, onClose}: {product: ProductItem | undefined, onClose: () => void}) => {

    const storage = getStorage(app);
    const { userData } = useSelector((state: RootState) => state.user);

    const [productData, setProductData] = useState<ProductItem>()
    const [rating, setRating] = useState<number>(0);
    const [recommended, setRecommended] = useState<string>();
    const [recommendedError, setRecommendedError] = useState<boolean>(false);

    const dispatch = useDispatch<Dispatch<UserActionTypes>>();

    useEffect(() => {
        setProductData(product);
    }, []);

    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<(IFileWithMeta)[]>([]);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleRemove = (f: IFileWithMeta) => (event: any) => {
        event.preventDefault();
        f.remove();
    };

    const handleChangeStatus = (file: IFileWithMeta, status: string, allFiles: IFileWithMeta[]) => {
        if (status === "removed") {
            let f = attachments.find((a) => a.meta.id === file.meta.id);
            let a = [...attachments];
            if (f)
                a.splice(a.indexOf(f), 1);
            setAttachments([...a]);
        } else {
            setAttachments([...allFiles.slice(0, 2)]);
        }
    };

    const submitReview = (values: Record<string, string | boolean>) => {
        return new Promise((resolve, reject) => {
            if (rating && rating > 0) {
                if (recommended && recommended.length > 0) {
                    if (attachments.length > 0) {
                        setImageLoading(true);
                        let uploaded: string[] = [];

                        attachments.forEach((a) => {
                            let uploadTask = uploadBytesResumable(ref(storage, `review_images/${userData.uid}/${a.file.name}`), a.file);
                            uploadTask.on(
                                'state_changed',
                                (snapshot) => {
                                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                                    switch (snapshot.state) {
                                        case 'paused':
                                            break;
                                        case 'running':
                                            break;
                                    }
                                },
                                (error) => {
                                    console.log(error);
                                },
                                () => {
                                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                        uploaded.push(downloadURL);
                                        if (uploaded.length === attachments.length) {
                                            await addReview({text: values["review"].toString(), images: uploaded});
                                        }
                                    });
                                }
                            );
                        });
                    } else {
                        addReview({text: values["review"]?.toString() ?? "", images: []}).then();
                    }
                } else {
                    setRecommendedError(true);
                    reject("");
                }
            } else {
                setError(true);
                reject("");
            }
        })
    }

    const addReview = async ({text, images}: {text: string, images: string[]}) => {
        let r:Review = new Review({
            name: userData?.firstName + " " + userData?.lastName[0],
            rating: rating,
            text: text,
            images: images,
            recommended: recommended === "Yes",
            uid: userData?.uid
        })

        await setDoc(doc(collection(products, product?.id, "reviews"), userData?.uid), r.toJson());
        await updateDoc(doc(users, userData?.uid), { productsReviewed: arrayUnion(product?.id) });
        const newUserData = await getDoc(doc(users, userData?.uid));
        dispatch(setUser(auth.currentUser, new UserData(newUserData.data())));
        onClose();
    }


    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                    <div style={{
                        position: "relative",
                        background: `#e7e8e3 url(${(productData?.thumbnail_src)}) center center / cover`,
                        width: "56px",
                        height: "56px",
                        marginRight: "16px",
                        borderRadius: "4px",
                        overflow: "hidden"
                    }} />
                    <div>
                        <p className={"text-sm font-medium font-sans"} >
                            Tell us more about
                        </p>
                        <p className={"text-base font-bold font-sans"}>
                            {productData?.title}
                        </p>
                    </div>
                </div>

                <div style={{ margin: "16px 0", width: "100%", height: "1px", background: "rgb(230,230,240)" }} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <p className={"text-base font-bold font-sans mb-2"}>
                            Overall Rating
                        </p>
                        {/*// @ts-ignore*/}
                        <Rating
                            // icon={<AiFillStar style={{ marginLeft: "2px" }} size={28} />}
                            emptySymbol={<AiOutlineStar style={{ marginLeft: "2px" }} className={"text-gray-300"} size={22} />}
                            fullSymbol={<AiFillStar style={{ marginLeft: "2px" }} className={"text-yellow-500"} size={22} />}
                            // value={0}
                            // precision={1}
                            initialRating={rating}
                            onChange={( newValue) => {
                                setRating(newValue)
                            }}
                        />
                        {error &&
                            <p className={"text-sm font-medium font-sans"} style={{ marginTop: "4px", color: "rgb(198,53,89)" }}>
                              Please select a star rating
                            </p>
                        }
                    </div>
                    {/*<div>*/}
                    {/*    <button className={"rounded-full px-5 py-1 bg-background-dark hover:bg-background hover:underline transition duration-200 font-sans font-medium text-sm"} onClick={() => setRating(0)}>*/}
                    {/*            Clear*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>

                <div style={{ margin: "16px 0", width: "100%", height: "1px", background: "rgb(230,230,240)" }} />
                <div>
                    <p className={"text-base font-bold font-sans"} style={{ marginBottom: "2px" }}>
                        Recommend product
                    </p>
                    <p className={"text-sm font-medium font-sans opacity-80"} style={{ marginBottom: "16px" }}>
                        Would you recommend this product to a friend?
                    </p>
                    <div style={{ border: "1px solid rgba(150,150,150,0.3)", display: "flex", maxWidth: "240px" }}>
                        {["Yes", "No"].map((v, index) => {
                            return <div
                                key={v}
                                onClick={() => {
                                    setRecommended(v)
                                }}
                                className={"flex items-center px-3 py-2 cursor-pointer hover:bg-background-dark"}
                                style={{ flex: 1, borderRight: (index !== 1) ? "1px solid rgba(150,150,150,0.3)" : "" }}
                            >
                                <div style={{
                                    background: recommended === v ? "#13172F" : "rgb(220,220,220)",
                                    width: "12px",
                                    height: "12px",
                                    marginRight: "12px",
                                    borderRadius: "50%"
                                }} />
                                <p
                                    className={"text-sm font-medium font-sans"}
                                    style={{ transition: "0.3s", transform: "translateY(-1px)" }}
                                >
                                    {v}
                                </p>
                            </div>
                        })}
                    </div>
                    {recommendedError &&
                    <p className={"text-sm font-medium font-sans"} style={{ marginTop: "8px", color: "rgb(198,53,89)" }}>
                      Please select one of these options.
                    </p>}
                </div>
                <div style={{ margin: "24px 0", width: "100%", height: "1px", background: "rgb(230,230,240)" }} />
                <div>
                    <p className={"text-base font-bold font-sans"} style={{ marginBottom: "2px" }}>
                        Add a Photo
                    </p>
                    <p className={"text-sm font-medium font-sans"} style={{ marginBottom: "16px" }}>
                        {"Shoppers find images more helpful than text alone."}
                    </p>
                    <Dropzone
                        multiple={true}
                        maxFiles={2}
                        inputContent={<p key={"dropzone"} className={"addImageBox"}>
                            <FiPlus size={24} color={"rgb(70,70,160)"} strokeWidth={"3px"} />
                        </p>}
                        inputWithFilesContent={<p key={"dropzone"} className={"addImageBox"}
                                                  style={{ opacity: attachments.length < 2 ? 1 : 0 }}>
                            <FiPlus size={24} color={"rgb(70,70,160)"} strokeWidth={"3px"} />
                        </p>}
                        PreviewComponent={(file: IPreviewProps) => {
                            return <div key={file.meta.id} className={"previewContainer"} style={{
                                backgroundImage: `url(${file.meta.previewUrl})`,
                                backgroundSize: "cover"
                            }}>
                                {imageLoading ? (
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        background: "rgba(0,0,0,0.5)",
                                        top: 0,
                                        position: "absolute",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex"
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(0,0,0,0.5)",
                                            top: 0,
                                            position: "absolute",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex"
                                        }}>
                                            {progress === 100 ? (
                                                <AiFillCheckCircle size={32} color={"white"} />
                                            ) : (
                                                <div style={{ transform: "translate(-18px, -18px)" }}>
                                                    {/*<CircularProgress variant={"determinate"}*/}
                                                    {/*                  style={{ position: "absolute" }} value={progress}*/}
                                                    {/*                  classes={{ colorPrimary: classes.progressBar }}*/}
                                                    {/*                  size={40} thickness={12} />*/}
                                                    {/*<CircularProgress variant={"determinate"}*/}
                                                    {/*                  style={{ position: "absolute" }} value={100}*/}
                                                    {/*                  classes={{ colorPrimary: classes.progressBackground }}*/}
                                                    {/*                  size={40} thickness={12} />*/}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={"previewDeleteIcon"}
                                         onClick={handleRemove(file.fileWithMeta)}>
                                        <FiX style={{ height: "14px", width: "14px", color: "white" }} />
                                    </div>
                                )}
                            </div>
                        }}
                        submitButtonDisabled={true}
                        onChangeStatus={handleChangeStatus}
                        disabled={imageLoading || attachments.length === 2}
                        accept="image/*"
                        styles={{
                            input: { display: "none" },
                            dropzone: {
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "8px",
                                border: "2px dashed rgba(230, 230, 230, 0)"
                            },
                            dropzoneDisabled: { borderRadius: "8px" },
                            dropzoneActive: {
                                background: "rgba(80,130,255,0.29)",
                                borderRadius: "8px",
                                border: "2px solid rgb(80,130,255)"
                            },
                            dropzoneReject: {
                                background: "rgb(235, 80, 60, 0.13)",
                                borderRadius: "8px",
                                border: "2px solid rgb(235, 80, 60)"
                            }
                        }} />
                </div>

                <div style={{ margin: "24px 0", width: "100%", height: "1px", background: "rgb(230,230,240)" }} />
                <FormWrapper onSubmit={submitReview}>
                    <FormTextarea name={"review"}
                               placeholder={"What did you like or dislike? How did the product help you?"}
                               label={"Written Review"}/>
                    <div style={{ height: "24px" }} />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <FormButton style={{ maxWidth: "280px" }}>
                            Submit Review
                        </FormButton>
                    </div>
                </FormWrapper>
            </div>
            <div />
        </>
    )
}

export default ManageProductReview;