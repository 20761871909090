import React from "react"
import { Router, useLocation } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/reducers"
import { navigate } from "gatsby"
import queryString from "query-string"
import SignIn from "../../page-components/account-pages/sign-in"
import Signup from "../../page-components/account-pages/sign-up"
import ForgotPassword from "../../page-components/account-pages/forgot-password"
import LoginSecurity from "../../page-components/account-pages/my-account-index/login-security/login-security"
import Overview from "../../page-components/account-pages/my-account-index/overview"
import YourAddresses from "../../page-components/account-pages/my-account-index/your-addresses/addresses"
import PendingReviews from "../../page-components/account-pages/my-account-index/pending-reviews/pending-reviews"
import OrdersHistory from "../../page-components/account-pages/my-account-index/your-orders/your-orders"
import OrderDetails from "../../page-components/account-pages/my-account-index/your-orders/order-details"
import PointsHistory from "../../page-components/account-pages/my-account-index/points-history/points-history"

const PrivateRoute = ({ component: Component, ...rest }) => {

    const { isAuthenticated } = useSelector((state: RootState) => state.user);
    const location = useLocation();

    if (isAuthenticated) {
        return <Component {...rest} />
    } else {
        if (typeof window !== 'undefined')
            navigate(`/account/sign-in?redirect=${location.pathname ?? '/account'}`)
        return <Component {...rest} />;
    }
};

const NoAuthRoute = ({ component: Component, redirect = true, ...rest }) => {

    const location = useLocation();
    const { isAuthenticated } = useSelector((state: RootState) => state.user);

    if (!isAuthenticated || !redirect) {
        return <Component {...rest} />
    } else {
        if (typeof window !== 'undefined')
            navigate((location && queryString.parse(location.search ?? "")?.redirect) ? (queryString.parse(location.search).redirect as string) : "/account")
        return <Component {...rest} />;
    }
};

const OpenRoute = ({ component: Component, ...rest }) => {
    return <Component {...rest} />
};

const Account = ({transitionStatus}) => {

    const location = useLocation();
    const { loading } = useSelector((state: RootState) => state.user);

    return (
        <div style={{ marginTop: "132px" }}>
            <div>
                {!loading && (
                        <Router location={location}>

                            <NoAuthRoute path="/account/sign-in" component={SignIn} transitionStatus={transitionStatus} redirect={true} />
                            <NoAuthRoute path="/account/become-a-member" component={Signup} redirect={false}  transitionStatus={transitionStatus}/>
                            <OpenRoute path="/account/forgot-password" component={ForgotPassword} transitionStatus={transitionStatus}/>

                            <PrivateRoute path={"/account"} component={Overview} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/profile"} component={LoginSecurity} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/addresses"} component={YourAddresses} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/review-purchases"} component={PendingReviews} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/orders"} component={OrdersHistory} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/orders/:id"} component={OrderDetails} transitionStatus={transitionStatus}/>
                            <PrivateRoute path={"/account/point-history"} component={PointsHistory} transitionStatus={transitionStatus}/>
                        </Router>
                    )
                }
            </div>
        </div>

    )

}

export default Account;