import React, { useEffect, useState } from "react"
import InlineLink from "../../../components/general/inline-link";
import Mock from "../../../images/ui/groomd_three.webp";
import SEO from "../../../components/seo/seo"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/reducers"
import { navigate } from "gatsby"
import CustomContainer from "../../../components/general/contianer"
import SignUpForm from "../auth-dialogs/sign-up-form"
import { imageKitLink } from "../../../utils/fomatters"

const Signup = () => {

    const [authenticated, setAuthenticated] = useState<boolean>(true);
    const {isAuthenticated} = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (isAuthenticated)
            navigate("/account");
        else
            setAuthenticated(false);
    }, []);

    return (
        <div className={"w-screen flex items-start md:items-center"} style={{ minHeight: "calc(100vh - 132px)", opacity: authenticated ? 0 : 1 }}>
            <SEO description={"Become a member at Groomd Mens Club to enjoy exclusive offers and rewards."} title={"Become a Member"} keywords={["groomd sign up", "groomd join", "groomd become a member", "groomd mens club"]}/>
            <div>
                <img alt={"Groomd Products"}
                     draggable={false}
                     src={imageKitLink('/product_images_v3/serum_extra.jpg', {tr: 'f-webp,h-1350'})} style={{
                    position: "absolute", width: "100vw", height: "calc(100vh - 112px)", maxHeight: "1100px", top: "112px", zIndex: 0
                }} className={"hidden md:block left-0 object-cover"}/>
            </div>
            <CustomContainer className={"relative flex justify-center"}>
                <div className={"max-w-lg w-full md:bg-zinc-100/70 md:border border-zinc-300 md:shadow-xl rounded-2xl md:py-6 md:px-8 md:backdrop-saturate-200 md:backdrop-filter md:backdrop-blur-xl"}>
                    <SignUpForm/>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "18px" }}>
                        <p className={"font-medium font-sans text-sm"}>
                            <InlineLink basic_link to={"/account/sign-in"} variant={"secondary"}>Return to Sign in</InlineLink>
                        </p>
                    </div>
                </div>
            </CustomContainer>
        </div>
    )
}

export default Signup;