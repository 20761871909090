import InlineLink from "../../../../components/general/inline-link"
import React from "react"
import { useLocation } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/reducers"
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight"
import { Link } from "gatsby"

const AccountHeader = ({orderId}:{orderId?:string}) => {

    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state.user);

    const isRoot = location.pathname === ("/account") || location.pathname === ("/account/")

    return (
        <>
            <div className={"flex items-center mb-2 space-x-4"}>
                <Link to={"/account"} className={`font-sans text-base font-medium ${isRoot ? "opacity-100" : "opacity-50"}`}>
                    Your Account
                </Link>
                { !isRoot &&
                    <FiChevronRight size={12} style={{ opacity: "0.8" }}/>
                }
                { !isRoot &&
                  <Link to={`${location.pathname}`} variant={"secondary"} className={"font-sans text-base font-medium "}>
                      {
                          (() => {
                              switch (location.pathname) {
                                  case "/account/orders":
                                      return "Your Orders";
                                  case "/account/addresses":
                                      return "Your Addresses";
                                  case "/account/profile":
                                      return "Login & Security";
                                  case "/account/review-purchases":
                                      return "Pending Reviews";
                                  case "/account/point-history":
                                      return "Points History";
                              }
                          })()
                      }
                  </Link>
                }

            </div>
            <h1 className={"font-display font-semibold mb-8 mt-0 text-4xl"}>
                {orderId ? "#"+orderId :
                    (() => {
                        switch (location.pathname) {
                            case "/account":
                                return `Welcome to your account, ${userData?.name?.split(" ")[0] ?? ""}`;
                            case "/account/orders":
                                if (orderId)
                                    return "#"+orderId
                                else
                                return "Your Orders";
                            case "/account/profile":
                                return "Login & Security";
                            case "/account/addresses":
                                return "Your Addresses";
                            case "/account/review-purchases":
                                return "Review Your Purchases";
                            case "/account/point-history":
                                return "Your Points History";
                        }
                    })()
                }
            </h1>
        </>
    )

}

export default AccountHeader;