import React, { useState } from "react"

interface FormInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    disableRounded?: boolean,
    label?: string,
    hideLabel?: boolean,
    error?: string,
    hideShadow?: boolean,
    rootClassName?: string
    end?: React.ReactNode
    endClick?: () => void
}

const FormInput = (props: FormInputProps) => {

    const inputProps = {...props};
    delete inputProps.disableRounded
    delete inputProps.label
    delete inputProps.hideLabel
    delete inputProps.error
    delete inputProps.hideShadow
    delete inputProps.rootClassName

    const [view, setView] = useState(false);

    const hasPrefix = props.type === "tel";

    const focusRing = !!props.error ? "focus:ring-red-400" : "focus:ring-primary-light";
    const focusBorder = !!props.error ? "focus:border-red-400" : "focus:border-primary-light"

    return (
        <div className={`relative font-sans ${props.rootClassName}`}>
            <div className={`relative -space-y-px ${props.hideShadow ? "" : "rounded-xl shadow-sm"}`}>
                <input {...inputProps} type={!view ? props.type : "text"}
                       placeholder={`${props.placeholder} ${props.required ? "*" : ""}`}
                       className={`
                       bg-background-lighter ${props.className} 
                       appearance-none relative block w-full px-3 ${hasPrefix ? "pl-10" : ""} 
                       ${(props.value?.toString()?.length ?? 0) > 0 ? "pt-4.5 pb-1.5" : "py-3" } 
                       transition duration-200 border ${props.error ? "border-red-300 z-10" : "border-gray-300"} 
                       placeholder-gray-500 text-gray-900 
                       focus:outline-none ${focusRing} ${focusBorder}
                       focus:z-20 ${props.disableRounded ? "" : "rounded-xl"}
                       `}
                       disabled={props.disabled}
                />
                {(props.type === "tel") &&
                    <div className={`absolute left-3 z-20 py-3 ${(props.value?.toString()?.length ?? 0) > 0 ? "pt-4.5 pb-1.5" : ""}`} style={{ top: "2px", transition: "200ms" }}>
                        +91
                    </div>
                }
                {(props.type === "password") &&
                    <div role="button" className={"hover:opacity-80 absolute h-full flex items-center right-3 z-20 top-1/2 transform -translate-y-1/2 uppercase tracking-widest text-xs"} onClick={() => { setView((prev) => !prev) }}>
                        {view ? "HIDE" : "SHOW"}
                    </div>
                }
                {(props.end) &&
                    <div role="button" className={"hover:opacity-80 absolute h-full flex items-center right-3 z-20 top-1/2 transform -translate-y-1/2 uppercase tracking-widest text-xs"} onClick={() => { props.endClick() }}>
                        {props.end}
                    </div>
                }
            </div>
            <label htmlFor={inputProps.id} className={`${props.hideLabel ? "sr-only" : `transform ${(props.value?.toString()?.length ?? 0) > 0 ? "opacity-80 -translate-y-0" : "-translate-y-1 opacity-0"} transition duration-200 uppercase tracking-widest absolute z-20 top-0 left-0 px-3 pt-1.5`}`} style={{ fontSize: "10px" }}>
                {props.label} {props.required ? "*" : ""}
            </label>
            {(!!props.error && props.error !== "empty") &&
            <p style={{ textTransform:"none"}} className={"-space-y-px text-sm mt-1 mb-1 text-red-700"}>
                {props.error}
            </p>
            }
        </div>
    )

}

export default FormInput;