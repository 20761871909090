import React from "react";
import {Transition} from "@headlessui/react";

const Fade = ({children, show, delay=0, appear}: {children: React.ReactNode, show: boolean, appear?: boolean, delay?:number}) => {

    return (
        <Transition
            show={show}
            appear={appear}
            enter={`transition ease-in-out delay-${delay} duration-300`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
    )

}

export default Fade;