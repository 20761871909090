import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../utils/firebase";
import SEO from "../../../components/seo/seo";
import Mock from "../../../images/ui/groomd_three.webp"
import FormWrapper from "../../../components/form-components/form-wrapper"
import FormInput from "../../../components/form-components/form-input-field"
import FormErrorText from "../../../components/form-components/form-error-text"
import FormButton from "../../../components/form-components/form-button"
import InlineLink from "../../../components/general/inline-link"
import CustomContainer from "../../../components/general/contianer"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { imageKitLink } from "../../../utils/fomatters"

const ForgotPassword = () => {

    const [success, setSuccess] = useState(false);

    // Handle Forgot Password
    const sendEmail = (values: Record<string, string | boolean>) => {
        return new Promise((resolve, reject) => {
            if (!success) {
                sendPasswordResetEmail(auth, values.email.toString())
                .then(() => {
                    resolve("Success");
                    setSuccess(true);
                })
                .catch(async (err) => {
                    console.log(err.code);
                    switch (err.code) {
                        case "auth/user-not-found":
                            return reject({email: "Couldn't find your account."});
                        case "auth/invalid-email":
                            return reject({email: "Please enter a valid Email Address."});
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
            } else {
                resolve("");
            }
        });
    };

    return (
        <div className={"w-screen flex items-start md:items-center"} style={{ minHeight: "calc(100vh - 132px)" }}>
            <SEO description={"Forgot your password? No worries we will help you reset it."} title={"Forgot Password?"} keywords={["groomd"]}/>
            <div>
                <img alt={"Groomd Products"}
                     draggable={false}
                     src={imageKitLink('/product_images_v3/serum_extra.jpg', {tr: 'f-webp,h-1350'})} style={{
                    position: "absolute", width: "100vw", height: "calc(100vh - 112px)", maxHeight: "1100px", top: "112px", zIndex: 0
                }} className={"hidden md:block left-0 object-cover"}/>
            </div>
            <CustomContainer className={"relative flex justify-center"}>
                <div className={"max-w-lg w-full md:bg-zinc-100/70 md:border border-zinc-300 md:shadow-xl rounded-2xl md:py-6 md:px-8 md:backdrop-saturate-200 md:backdrop-filter md:backdrop-blur-xl"}>
                    <FormWrapper id={"forgot_password_form"} onSubmit={sendEmail}>
                        <h1 className={"text-3xl md:text-4xl mb-1 font-display font-extrabold"}>Forgot Password</h1>
                        <p className={"text-base opacity-80 font-sans font-medium pb-4"}>Enter your email below and we’ll send you reset instructions.</p>

                        <FormInput id={"forgot_password_email"} placeholder={"Your Email"} label={"Your Email"} name={"email"} required type={"email"} autoComplete={"email"}/>
                        <div>
                            <FormErrorText/>
                            <FormButton id={"forgot_password_button"} className={`${success ? "bg-green-800 active:bg-green-600 pointer-events-none" : ""}`} style={{ marginTop: "18px" }}>
                                {success ?
                                    <span className={"flex items-center"}>
                                        <FiCheck color={"white"} className={"mr-2"} size={16}/> Sent
                                    </span>
                                    :
                                    "Send Instructions"
                                }
                            </FormButton>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "18px" }}>
                            <p className={"font-medium font-sans text-sm"}>
                                <InlineLink basic_link to={"/account/sign-in"} variant={"secondary"}>
                                    Return to Sign in
                                </InlineLink>
                            </p>
                        </div>
                    </FormWrapper>
                </div>
            </CustomContainer>
        </div>
    )
}

export default ForgotPassword;