import React from "react";

function FormErrorText(
    {
        error,
        showError,
    } : {
        error?: Record<string, string | boolean> | string,
        showError?: boolean,
    }) {
    let errorValue;

    if (typeof error === "string")
        errorValue=error;
    else
        errorValue=error?.text;

    return (
        (showError && errorValue!==undefined && errorValue!=="") ?
        <div style={{ display: "flex", alignItems: "center", marginBottom: '8px 0'}}>
          <p className={"text-sm mb-1 font-sans"} style={{color:"rgb(198,53,89)", marginBottom: "2px", minHeight: "12px", textTransform:"none"}} >
              {errorValue}
          </p>
        </div>
        :
        <div/>
)
}

export default FormErrorText;