import React  from "react"
import SEO from "../../../components/seo/seo"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/reducers"
import CustomContainer from "../../../components/general/contianer"
import SignInForm from "../auth-dialogs/sign-in-form"
import InlineLink from "../../../components/general/inline-link"
import FormButton from "../../../components/form-components/form-button"
import { imageKitLink } from "../../../utils/fomatters"

const SignIn = () => {

    const {isAuthenticated} = useSelector((state: RootState) => state.user);

    return (
        <div className={"w-screen flex items-start md:items-center"} style={{ minHeight: "calc(100vh - 132px)", opacity: isAuthenticated ? 0 : 1 }}>
            <SEO description={"Welcome back! Sign In to your account manage your \"Groomd Mens Club\" membership and track your orders."} title={"Sign in"} keywords={["groomd login", "groomd sign in", "groomd mens club"]}/>
            <div>
                <img alt={"Groomd Products"}
                     draggable={false}
                     src={imageKitLink('/product_images_v3/serum_extra.jpg', {tr: 'f-webp,h-1350'})} style={{
                    position: "absolute", width: "100vw", height: "calc(100vh - 112px)", maxHeight: "1100px", top: "112px", zIndex: 0
                }} className={"hidden md:block left-0 object-cover"}/>
            </div>
            <CustomContainer className={"relative flex justify-center"}>
                <div className={"max-w-lg w-full md:bg-zinc-100/70 md:border border-zinc-300 md:shadow-xl rounded-2xl md:py-6 md:px-8 md:backdrop-saturate-200 md:backdrop-filter md:backdrop-blur-xl"}>
                    <SignInForm/>
                    <FormButton inverse basic_link disableArrowAnimation to={"/account/become-a-member"} style={{ marginTop: "12px" }} className={"border border-gray-400"}>
                            Become a Member
                    </FormButton>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "18px" }}>
                        <p className={"font-medium font-sans text-sm"}>
                            <InlineLink to={"/groomd-rewards"} variant={"secondary"}>Loyalty Program Info</InlineLink>
                        </p>
                    </div>
                </div>
            </CustomContainer>
        </div>
    )
}

export default SignIn;