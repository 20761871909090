import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { RootState } from "../../../../redux/reducers"
import { Order } from "../../../../models/order"
import AccountHeader from "../account-header/account-header"
import CustomContainer from "../../../../components/general/contianer"
import { getDocs, orderBy, query, where } from "firebase/firestore/lite"
import { orders } from "../../../../utils/firebase";
import Fade from "../../../../components/transition-wrapper/fade"
import OrderListItem from "../your-addresses/order-list-item";
import CustomTransitionLink from "../../../../components/transition-wrapper/custom-transition-link"

const OrdersHistory = () => {

    const { userData } = useSelector((state: RootState) => state.user);

    const [loading, setLoading] = useState<boolean>(true);
    const [myOrders, setMyOrders] = useState<Order[]>([]);

    useEffect(() => {
        getDocs(query(orders, where('uid', '==', userData?.uid), orderBy("created", "desc")))
            .then((snapshot) => {
                if (snapshot.empty) {
                    setLoading(false);
                    setMyOrders([]);
                } else {
                    let t: any[] = []
                    snapshot.docs.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        t.push(new Order(data));
                    })
                    setMyOrders(t);
                    setLoading(false);
                }
            })
    }, [])

    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            <Fade show={!loading}>
                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                    {myOrders.length === 0 &&
                    <div>
                      <p className={"text-lg font-sans font-medium"}>
                        You haven't placed any orders yet.
                      </p>
                    </div>
                    }
                    {
                        myOrders.map((o) =>{
                            return (
                                <CustomTransitionLink key={o.id} to={"/account/orders/" + o.id}>
                                    <OrderListItem order={o}/>
                                </CustomTransitionLink>
                            )
                        })
                    }
                </div>
            </Fade>
        </CustomContainer>
    )
}

export default OrdersHistory;