import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import ChangePassword from "./change-password";
import { RootState } from "../../../../redux/reducers"
import { UserActionTypes } from "../../../../redux/types/user-type"
import { CartActionTypes } from "../../../../redux/types/cart-type"
import { BirthDate, UserData } from "../../../../models/user-data"
import { updateUser } from "../../../../redux/actions/user/user-actions"
import FormWrapper from "../../../../components/form-components/form-wrapper"
import FormButton from "../../../../components/form-components/form-button"
import CustomContainer from "../../../../components/general/contianer"
import AccountHeader from "../account-header/account-header"
import ZoomFadeDialog from "../../../../components/dialogs/zoom-fade-dialog"
import FormInput from "../../../../components/form-components/form-input-field";
import Fade from "../../../../components/transition-wrapper/fade"
import FormErrorText from "../../../../components/form-components/form-error-text"
import { doc, getDoc, updateDoc } from "firebase/firestore/lite"
import { auth, users, user_exists } from "../../../../utils/firebase";
import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updateEmail, User } from "firebase/auth"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { FiX } from "@react-icons/all-files/fi/FiX"
import { FiLock } from "@react-icons/all-files/fi/FiLock"

//TODO Readjust Code
const LoginSecurity = () => {

    const { userData, } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<Dispatch<UserActionTypes | CartActionTypes>>();

    const [changePassword, setChangePassword] = useState(false);
    const [changed, setChanged] = useState(false);

    const [askPassword, setAskPassword] = useState(false);
    const [saved, setSaved] = useState(false);

    const onSave = (values: Record<string, string | boolean>) => {
        return new Promise(async (resolve, reject) => {

            let firebaseUser: User = auth.currentUser;

            let d = parseInt(values["day"].toString());
            let m = parseInt(values["month"].toString());

            if (values["day"] || values["month"]) {

                if (!(d > 0 && d < 32 && m<=12 && m>0)) {
                    return reject({day: "Invalid Date", month: "empty"})
                }
            }

            if (values.email !== userData?.email) {

                let existsDoc = await getDoc(doc(user_exists, values.email.toString()));
                if (existsDoc.exists()) {
                    return reject({email: "Email is already in use."})
                }

                await signInWithEmailAndPassword(auth,userData?.email?.toString() ?? "", values.password.toString())
                    .then(async (u) => {
                        if (u){
                            await reauthenticateWithCredential(u.user, EmailAuthProvider.credential(userData?.email?.toString(), values.password.toString()));
                        }
                    })
                    .catch((err) => {
                        switch (err.code) {
                            case "auth/user-not-found":
                                return reject({ email: "We didn't find an account with this email address." });
                            case "auth/wrong-password":
                                return reject({ password: "That's an incorrect password. Try again." });
                            case "auth/invalid-email":
                                return reject({ email: "Email Address doesn't look right." });
                            default:
                                return reject("Something went wrong on our side.");
                        }
                    });
            }



            updateEmail(firebaseUser, values.email.toString()).then(() => {
                updateDoc(doc(users, userData?.uid), {
                    firstName: values.firstName.toString(),
                    lastName: values.lastName.toString(),
                    email: values.email.toString(),
                    birthDate: {
                        day: isNaN(d) ? null : d,
                        month: isNaN(m) ? null : m
                    }
                }).then(async () => {
                    let u = new UserData(userData?.toJson());
                    u.firstName = values.firstName.toString();
                    u.lastName =  values.lastName.toString();
                    u.email = values.email.toString();
                    u.birthDate = new BirthDate({
                        day: isNaN(d) ? null : d,
                        month: isNaN(m) ? null : m
                    })

                    dispatch(updateUser(u));
                    setSaved(true);
                    setAskPassword(false);

                    resolve("");
                })
            }).catch((_) => {
                reject({email: "We were not able to update your email!"})
            });
        })
    }

    return (
        <CustomContainer style={{ marginTop: "64px" }}>
            <AccountHeader/>
            <div className={"grid grid-cols-1 sm:grid-cols-2 gap-12 max-w-6xl"}>
                <div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p className={"font-sans text-base font-medium"}>
                                Personal Details
                            </p>
                            <div style={{ marginLeft: "auto" }}/>
                            <FiCheck size={16} color={"rgb(24,128,74)"} style={{ transform: "translateY(1px)", marginRight: "8px", opacity: saved ? 1 : 0 }}/>
                            <p className={"font-sans text-base font-bold"} style={{ color: "rgb(24,128,74)", opacity: saved ? 1 : 0 }}>
                                Saved
                            </p>
                        </div>
                        <div style={{ height: "8px", borderBottom: "1px solid rgba(50,80,120, 0.7)", width: "100%", marginBottom: "12px" }}/>
                        <FormWrapper className={"space-y-4"} onValueChange={(values) => {
                            if (saved)
                                setSaved(false);

                            if (values.email !== userData?.email) {
                                if (!askPassword)
                                    setAskPassword(true);
                            } else {
                                if (askPassword)
                                    setAskPassword(false)
                            }

                        }} onSubmit={onSave} initialValues={{ firstName: userData?.firstName ?? "", lastName: userData?.lastName ?? "", email: userData?.email ?? "", day: userData?.birthDate?.day?.toString()?.padStart(2, "0") ?? "", month: userData?.birthDate?.month?.toString()?.padStart(2, "0") ?? "" }}>
                            <div className={"flex space-x-4"}>
                                <FormInput id={"login_security_firstName"} name={"firstName"} rootClassName={"w-full"} type={"text"} placeholder={"First Name"} label={"First Name"} required/>
                                <FormInput id={"login_security_lastName"} name={"lastName"} rootClassName={"w-full"} type={"text"} placeholder={"Last Name"} label={"Last Name"} required/>
                            </div>
                            <FormInput name={"email"} type={"email"} label={"Email Address"} placeholder={"Your Email"} required/>
                            <Fade show={askPassword}>
                                <FormInput name={"password"} type={"password"} label={"Confirm Password"} placeholder={"Re-enter your password"} required={askPassword}/>
                            </Fade>
                            <div className={"grid grid-cols-2 gap-4"}>
                                <p className={"font-sans font-medium text-sm col-span-2 -mb-3"}>
                                    Birth Date
                                </p>
                                <div className={"relative"}>
                                    <FormInput name={"day"} label={"Day"} rootClassName={"w-full"} placeholder={"DD"} type={"number"} style={{ flex: 1, flexShrink: 0 }} disabled={userData?.birthDate?.isValid}/>
                                    <FiLock className={`${userData?.birthDate.isValid ? 'opacity-100' : 'opacity-0'} z-10 top-1/2 transform -translate-y-1/2 right-4 absolute`} size={14}/>
                                </div>
                                <div className={"relative"}>
                                    <FormInput name={"month"} label={"Month"} rootClassName={"w-full"} placeholder={"MM"} type={"number"} style={{ flex: 1, flexShrink: 0 }} disabled={userData?.birthDate?.isValid}/>
                                    <FiLock className={`${userData?.birthDate.isValid ? 'opacity-100' : 'opacity-0'} z-10 top-1/2 transform -translate-y-1/2 right-4 absolute`} size={14}/>
                                </div>
                                {userData.birthDate.isValid && (
                                    <div className={"col-span-2"}>
                                        <p className={'font-sans text-sm leading-tight'}>
                                            Please contact at <a className={"text-primary-default underline px-1"} href={"mailto:care@groomd.co.in"}>care@groomd.co.in</a> to update your date.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <FormErrorText/>
                            <div style={{ width: "100%", marginTop: "24px", display: "flex", justifyContent: "flex-end" }}>
                                <FormButton style={{ maxWidth: "160px", margin: 0 }}>
                                    Save
                                </FormButton>
                            </div>
                        </FormWrapper>
                    </div>
                </div>
                <div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p className={"font-sans text-base font-medium"}>
                                Security
                            </p>
                            <div style={{ marginLeft: "auto" }}/>
                            <FiCheck size={16} color={"rgb(24,128,74)"} style={{ transform: "translateY(1px)", marginRight: "8px", opacity: changed ? 1 : 0 }}/>
                            <p className={"font-sans text-base font-bold"} style={{ color: "rgb(24,128,74)", opacity: changed ? 1 : 0 }}>
                                Changed
                            </p>
                        </div>
                    </div>
                    <div style={{ height: "8px", borderBottom: "1px solid rgba(50,80,120, 0.7)", width: "100%", marginBottom: "12px" }}/>
                    <FormInput endClick={() => { setChangePassword(!changePassword) }} end={"Change"} name={"change-password"} label={"Password"} type={"text"} placeholder={"Password"} value={"•••••••••••••••"} disabled required/>
                </div>
            </div>
            <ZoomFadeDialog open={changePassword} onClose={() => { setChangePassword(false); } }>
                <div className={"px-6 py-6"}>
                    <div className={"flex items-center space-x-1 pb-4 mb-4 border-b border-gray-300"}>
                        <div role={"button"} className={"p-2 transform -translate-x-2 rounded-full hover:text-red-900 hover:bg-background-dark cursor-pointer"} onClick={() => { setChangePassword(false); }}>
                            <FiX size={18} />
                        </div>
                        <p className={"font-bold font-sans text-2xl"}>
                            Change Password
                        </p>
                    </div>
                    <ChangePassword
                        onClose={async () => {
                            setChangePassword(false); setChanged(true);
                        }}
                    />
                </div>
            </ZoomFadeDialog>
        </CustomContainer>
    )
}

export default LoginSecurity;